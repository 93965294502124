import { EMAIL_REGEXP } from "modules/common/const";
import * as yup from "yup";

export const stringScheme = () => yup.string().required("web.form.string.required");

export const booleanScheme = () => yup.boolean().required();

export const booleanOptionScheme = () => yup.boolean().optional();

export const userNameScheme = () => yup.string().required("web.form.username.required");

export const loginPasswordScheme = () => yup.string().required("web.form.password.required");

export const newPasswordScheme = () =>
  yup
    .string()
    .min(8, "web.form.newPassword.min")
    .max(13, "web.form.newPassword.max")
    .matches(/^(?=.*[a-z])/, "web.registration.newPassword.format")
    .required("web.registration.newPassword.required");

export const mobileNumberScheme = () => yup.number().integer().required("web.form.mobileNumber.required");

export const emailScheme = () => yup.string().matches(EMAIL_REGEXP).required("web.form.email.required");
