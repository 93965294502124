"use client";
import { useCallback } from "react";

import { useDispatch } from "react-redux";
// import { Link } from 'react-router-dom';
import {
  Button,
  ButtonVariant,
  CheckboxField,
  PasswordField,
  TextField,
  Typography,
} from "@hkexpressairwayslimited/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { envConfig } from "env";
import {
  booleanOptionScheme,
  emailScheme,
  loginPasswordScheme,
} from "modules/common/form-validation/utils/formValidation";
import { showLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { useTransContent } from "modules/common/trans-content/transContent";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

interface IFormInputs {
  email: string;
  password: string;
  rememberMe?: boolean;
}

const schema = yup
  .object({
    email: emailScheme(),
    password: loginPasswordScheme(),
    rememberMe: booleanOptionScheme(),
  })
  .required();

const LoginContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTransContent();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onRememberMe = useCallback((e: any) => {
    const rememberMe = e.target.checked;
  }, []);

  const onSubmit = useCallback((e: any) => {
    dispatch(showLoadingBackdrop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form method='POST' action={envConfig.loginUrl}>
      <Box display='flex' justifyContent='center'>
        <Box display='flex' width={{ xs: "400px" }}>
          <Grid container spacing={3} columns={12}>
            <Grid item xs={12}>
              <h1 className={classNames(Typography.H1)}>{t("web.login.title")}</h1>{" "}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id={"email"}
                    label={t("web.login.email.placeholder")}
                    defaultValue={""}
                    isError={!!errors.email?.message}
                    helperText={t(errors.email?.message || "")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='password'
                control={control}
                render={({ field }) => (
                  <PasswordField
                    {...field}
                    id={"password"}
                    label={t("web.login.password.placeholder")}
                    isError={!!errors.password?.message}
                    helperText={t(errors.password?.message || "")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name='rememberMe'
                control={control}
                render={({ field }) => (
                  <CheckboxField
                    {...field}
                    id={"rememberMe"}
                    label={""}
                    onChange={onRememberMe}
                    itemList={[
                      {
                        key: "rememberMe",
                        label: t("web.login.rememberMe.placeholder"),
                        defaultChecked: false,
                      },
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <div className='form-row'>
                <Button type='submit' variant={ButtonVariant.Primary} onClick={onSubmit}>
                  {t("web.login.loginBtn")}
                </Button>
              </div>
            </Grid>

            <Grid item xs={12}>
              <span className={classNames(Typography.WARNING)}>
                {"Please enter any email and password to sign in."}
              </span>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </form>
  );
};

export default LoginContainer;
